import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-email"
export default class extends Controller {
  static targets = ["provider", "identifier", 'workEmail', 'personalEmail', 'providerDiv'];
  initialize() {
    let self = this;
    this.setEmailProviderField();
  }

  setEmailProviderField() {   
    let p = this.providerTarget;
    let identifier = p.options[p.selectedIndex].dataset.info;
    this.identifierTarget.value = identifier;
  }

  setWorkEmailToPersonalEmail(){
    let personalEmail = this.personalEmailTarget;
    if (personalEmail.checked){
      this.workEmailTarget.value = personalEmail.value;
      this.providerDivTarget.classList.add('invisible');
      this.providerTarget.value = null;
    } else {
      this.workEmailTarget.value = null;
      this.providerDivTarget.classList.remove('invisible');
    }

  }
}
