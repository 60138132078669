import React, { Component } from "react";
import axios from "axios";
import Avatar from "./Avatar";
import { formatRelativeDate, csrfToken } from "./helpers";

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      comment: "",
    };
    this.setCommentValue = this.setCommentValue.bind(this);
    this.postComment = this.postComment.bind(this);
    this.loadComments = this.loadComments.bind(this);
  }

  setCommentValue(event) {
    this.setState({ comment: event.target.value });
  }

  loadComments() {
    axios
      .get(`/comments/${this.props.resourceType}/${this.props.resourceId}.json`)
      .then((res) => {
        this.setState({
          comments: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadComments();
  }

  postComment(event) {
    event.preventDefault();
    let token = csrfToken();
    axios.defaults.headers.common["X-CSRF-Token"] = token;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios({
      method: "post",
      url: "/comments",
      data: {
        comment: {
          comment: this.state.comment,
          commentable_type: this.props.resourceType,
          commentable_id: this.props.resourceId,
        },
      },
    })
      .then((res) => {
        this.loadComments();
        this.setState({ comment: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  render() {
    return (
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="notes-title"
                className="text-lg font-medium text-gray-900"
              >
                Private notes
              </h2>
            </div>
            <div className="px-4 py-6 sm:px-6">
              <ul role="list" className="space-y-8">
                {this.state.comments.map((comment) => (
                  <li key={comment?.id} className="pb-3">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <Avatar
                          url={comment?.user?.avatar}
                          initials={comment?.user?.initials}
                        />
                      </div>
                      <div>
                        <div className="text-sm">
                          <a
                            href='#'
                            className="font-medium text-gray-900"
                          >
                            {comment?.user.full_name}{" "}
                            <span className="text-gray-500 font-medium text-xs">
                              {formatRelativeDate(comment.created_at)}
                            </span>{" "}
                          </a>
                        </div>
                        <div className="mt-1 text-sm text-gray-700">
                          <p>{comment?.comment}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="min-w-0 flex-1">
                <form onSubmit={this.postComment}>
                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium leading-6 text-gray-900 sr-only"
                    >
                      Ask me a question...
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="question"
                        id="question"
                        className="p-4 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                        placeholder="Post a comment..."
                        value={this.state.comment}
                        onChange={this.setCommentValue}
                      />
                      <div className="absolute px-2 inset-y-0 right-0 flex items-center">
                        <button
                          type="submit"
                          className="inline-flex xs:ml-2 items-center rounded-md px-2 py-2 text-sm font-semibold bg-gray-600 text-gray-100 shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-5 w-5 text-gray-200 mr-1"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line x1="22" y1="2" x2="11" y2="13"></line>
                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
