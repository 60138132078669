import React, { useEffect, useState } from "react";
import axios from "axios";
import Avatar from "../Avatar";
const tabs = [
  { id: "draft", name: "Draft", href: "#", current: true },
  { id: "pending", name: "Pending", href: "#", current: false },
  { id: "completed", name: "Completed", href: "#", current: false },
  { id: "scheduled", name: "Scheduled", href: "#", current: false },
  { id: "expired", name: "Expired", href: "#", current: false },
  { id: "archived", name: "Archived", href: "#", current: false },
];

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import ContractStatus from "./ContractStatus";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function contractOffersUrl(status) {
  return `/paperworks/status/${status}.json`;
}

export default function Contracts() {
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    axios(contractOffersUrl("draft"))
      .then((res) => {
        setContracts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function noContracts() {
    return (
      <div className="mx-auto w-3/4 py-10 sm:px-6">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex-1 min-w-0">
            <h2 className="text-semibold py-6 text-center text-gray-900">
              We could not find any contracts with this status.
            </h2>
            <p className="mt-2 text-base text-gray-500">
              Paperworks are legal binding documents between your organization
              and its employees. Example include employment contracts, promotion
              contracts or employment confirmation.
            </p>
          </div>
        </div>
      </div>
    );
  }

  function contractOffers() {
    return (
      <ul role="list" className="divide-y divide-gray-100">
        {contracts?.map((contract) => (
          <li
            key={contract?.id}
            className="relative flex justify-between gap-x-6 py-5"
          >
            <div className="flex gap-x-4">
              <Avatar url={null} initials={contract?.initials} size={12} />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <a href={contract?.href}>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {contract?.candidate_name}
                  </a>
                </p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  <span className="relative truncate">
                    {contract?.offer_sent_at}
                  </span>
                  {ContractStatus({ status: contract?.status })}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">
                  {contract?.job_title}
                </p>
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  {contract?.starts_on}
                </p>
              </div>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
      </ul>
    );
  }

  function handleTabClick(status) {
    axios(contractOffersUrl(status))
      .then((res) => {
        setContracts(res.data);
        tabs.forEach((tab) => (tab.current = false));
        tabs.find((tab) => tab.id === status).current = true;
        tabs.find((tab) => tab.id === status).count = res.data.length;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="bg-white px-4">
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                onClick={() => handleTabClick(tab.id)}
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-sky-500 text-sky-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current
                        ? "bg-sky-100 text-sky-600"
                        : "bg-gray-100 text-gray-900",
                      "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>

      {contracts.length > 0 ? contractOffers() : noContracts()}
    </div>
  );
}
