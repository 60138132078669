import moment from 'moment';
import axios from 'axios';
export function formatDate(date) {
  return new Date(date).toLocaleDateString('en-us',
    { weekday: "long", year: "numeric", month: "short", day: "numeric" })
}

export function formatRelativeDate(date) {
  return moment(date).fromNow();
}

export function csrfToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

export function logout(event, logoutUrl, loginUrl) {
  event.preventDefault();
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken();
  axios.defaults.headers.common["Accept"] = "application/json";
  axios
    .delete(logoutUrl)
    .then((res) => {
      if (res.status === 204) {
        window.location.href = loginUrl;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}