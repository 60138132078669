import React, { Fragment, useState, useRef, useEffect } from "react";
import { ArrowUpTrayIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { csrfToken } from "../helpers";

export default function Task({ task }) {
  const [open, setOpen] = useState(false);

  function deleteTask(e) {
    e.preventDefault();
    let token = csrfToken();
    axios.defaults.headers.common["X-CSRF-Token"] = token;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios
      .delete(`/people/${task.assignee.id}/tasks/${task.id}.json`)
      .then((res) => {
        setOpen(false);
        location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const cancelButtonRef = useRef(null);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  function ActionButton() {
    return (
      <a href="#" onClick={handleOpen}>
        <ArrowUpTrayIcon className="h-4 w-4 text-sky-600" aria-hidden="true" />
      </a>
    );
  }

  return (
    <>
      <ActionButton />
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 w-[650px] pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  sm:p-6">
                  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {task?.name}
                      </h3>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 bg-gray-200 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Created by
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {task?.creator?.full_name} on {task?.created_at}
                          </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Assigned to
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {task?.creator?.full_name}
                          </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Due on
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {task?.due_date ? task?.due_date : "No due date"}
                          </dd>
                        </div>

                        {task?.completed && (
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Completed on
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {task?.completed_at}
                            </dd>
                          </div>
                        )}

                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Related document:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {task?.document
                              ? <p className="text-gray-500 text-sm italic overflow-hidden">
                               <a href={task.document_url} className="underline" target="_blank">{task.document}</a>
                              </p>
                              : "No description"}
                          </dd>
                        </div>
                      
                      </dl>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => deleteTask(e)}
                    >
                      Delete task
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
