/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export default function OffboardingActionModal({
  title,
  message,
  url,
  formToken,
  useIcon = false,
  iconSize = "6",
}) {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  function DestroyActionButton() {
    return (
      <button
        className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm sm:ml-3 sm:w-auto"
        onClick={handleOpen}
      >
        {title}
      </button>
    );
  }

  function DestroyActionIcon() {
    return (
      <a href="#" onClick={handleOpen}>
        <TrashIcon className={`h-${iconSize} w-${iconSize} text-red-600`} />
      </a>
    );
  }

  return (
    <>
      {useIcon ? <DestroyActionIcon /> : <DestroyActionButton />}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <form
                    data-controller="form-loading"
                    data-form-loading-target="form"
                    action={url}
                    data-turbo="false"
                    method="post"
                  >
                    <input
                      name="authenticity_token"
                      value={formToken}
                      type="hidden"
                    />

                    <input name="_method" value="delete" type="hidden" />
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          {title}
                        </Dialog.Title>
                        <fieldset>
                          <div className="my-6 space-y-3">
                            <div className="flex items-center gap-x-3">
                              <input
                                id="applications"
                                name="applications"
                                value='automatic'
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600"
                              />
                              <label
                                htmlFor="automatic"
                                className="block text-sm font-medium leading-6 text-gray-600"
                              >
                                Offboard this employee and remove them from all applications.
                              </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                              <input
                                id="applications"
                                name="applications"
                                value='manual'
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600"
                              />
                              <label
                                htmlFor="manual"
                                className="block text-sm font-medium leading-6 text-gray-600"
                              >
                                Offboard this employee from Clio only, I will remove them from other applications manually. 
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{message}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        {title}
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
