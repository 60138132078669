import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";


export default function CandidadatSignatureForm({ formToken, url }) {
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  function CalloutBtn() {
    return (
      <button
        onClick={handleOpen}
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
      >
        Sign contract offer
      </button>
    );
  }

  return (
    <>
      <CalloutBtn />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form
                      action={url}
                      method="post"
                      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    >
                      <input
                        name="authenticity_token"
                        value={formToken}
                        type="hidden"
                      />
                      <input name="_method" value="put" type="hidden" />
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-sky-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Sign offer letter electronically
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-sky-700 text-sky-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-sky-300">I accept this offer and look forward to joining the team.</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="signature"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                 Type your full name:
                                </label>
                                <div className="mt-1">
                                <input
                              type="text"
                              name="employee_signature"
                              id="signature"
                              required
                              className="signature block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                              
                            />
                                </div>
                              </div>
                            </div>
                            <div className="pt-4 pb-6">
                              <div className="mt-4 flex text-sm">
                                <span className="text-sm leading-6 group inline-flex items-center text-gray-500 hover:text-gray-900">
                                  <QuestionMarkCircleIcon
                                    className="h-5 w-5 text-gray-300 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  By clicking the button below: I certify that I
                                  consent to conduct all affairs relating to the
                                  documents listed above electronically. I
                                  further certify that I have electronically
                                  accessed the documents listed above, and that
                                  I have read them. I adopt the above electronic
                                  signature as my signature, and hereby
                                  electronically sign the documents listed
                                  above. I acknowledge that I have accessed,
                                  have read and hereby agree to the Clio's
                                  Terms of Service, and that I authorize the
                                  Clio services, in the manner designated
                                  therein, to process the documents and
                                  signatures provided herewith and to create,
                                  store, and communicate electronic records of
                                  the documents listed above.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm focus:ring-offset-2"
                        >
                         Sign Offer letter
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
