import React from "react";

export default function ActionBtn({ title, size = "small" }) {
  const btnSize =
    size === "small"
      ? "inline-flex inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
      : "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700";
  const btnClass = ` ${btnSize}`;
  return (
    <div className="px-2 py-6 text-right sm:py-4">
      <button type="submit" className={btnClass}>
        {title}
      </button>
    </div>
  );
}
