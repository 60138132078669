import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import axios from "axios";
import { csrfToken } from "../helpers";

export default function NewTask({ url }) {
  const [open, setOpen] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskDocument, setTaskDocument] = useState('');
  const [documents, setDocuments] = useState([]);
  const [message, setMessage] = useState(null);
  const [dueDate, setDueDate] = useState('');

  const cancelButtonRef = useRef(null);

  function handleTaskNameChange(event) {
    setTaskName(event.target.value);
  }

  function handleTaskDocumentChange(doc) {
    setTaskDocument(doc.value);
  }

  function handleDueDateChange(event) {
    setDueDate(event.target.value);
  }

  const closeModal = (e) => { 
    setOpen(false);
    location.reload();
    return false;
  }

  function createTask(event) {
    event.preventDefault();

    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken();
    axios.defaults.headers.common["Accept"] = "application/json";

    axios
      .post(url, {
        task: { name: taskName, document_id: taskDocument, due_date: dueDate },
      })
      .then((res) => {
        setTaskName("");
        setTaskDocument(null);
        setDueDate(null);
        setMessage(res.data.message);
        setTimeout(() => {
          setMessage(null);
        }, 10000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    axios(`/documents.json`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function ActionButton() {
    return (
      <a
        href="#"
        className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        onClick={handleOpen}
      >
        Add new task
      </a>
    );
  }

  return (
    <>
      <ActionButton />
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 w-[650px] pb-4 text-left shadow-xl transform transition-all sm:my-8  sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="my-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-green-700"
                      >
                        {message}
                      </Dialog.Title>
                    </div>
                  </div>
                  <form
                    method="post"
                    onSubmit={(event) => {
                      createTask(event);
                    }}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Write a short description for this task
                        </Dialog.Title>
                        <div className="mt-2 mb-8 block">
                          <input
                            type="text"
                            name="name"
                            value={taskName}
                            onChange={handleTaskNameChange}
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          When is this task due?
                        </Dialog.Title>
                        <div className="mt-2 mb-8 block">
                          <input
                            type="date"
                            name="due_date"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            id="date"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Select document related to this task{" "}
                          <span className="text-gray-400 italic">
                            (optional)
                          </span>
                        </Dialog.Title>
                        <div className="mt-2 mb-8 block">
                          <Select
                            isClearable={true}
                            options={documents}
                            onChange={handleTaskDocumentChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Add task
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
