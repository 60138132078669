import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { csrfToken } from "../helpers";

export default function Apps({ employeeName, url }) {
  const [apps, setApps] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);

  useEffect(() => {
    axios(`/applications/installed.json`)
      .then((res) => {
        setApps(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function setValues(value) {
    let app = selectedApps.find((app) => app.identifier === value[0].app);
    let v = value.map((v) => v.value);
    app.workspaces = [...v];
  }

  function selectApp(event) {
    if (event.target.checked) {
      let app = { identifier: event.target.value, workspaces: [] };
      setSelectedApps([...selectedApps, app]);
    } else {
      selectedApps.splice(selectedApps.indexOf(event.target.value), 1);
      setSelectedApps(
        selectedApps.filter((app) => app.identifier !== event.target.value)
      );
    }
  }

  function ShowInstalledApps() {
    return (
      <form
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        method="post"
        action={url}
      >
        <input name="authenticity_token" value={csrfToken()} type="hidden" />
        <input name="onboarding[service]" value="apps" type="hidden" />
        <input name="onboarding[apps]" value={JSON.stringify(selectedApps)} type="hidden" />

        <div className="px-4 py-6 sm:p-8">
          <div className="max-w-2xl space-y-10">
            <fieldset>
              <div className="mt-6 space-y-6">
                {apps?.map((app) => (
                  <div key={app.identifier} className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="app"
                        name="app"
                        type="checkbox"
                        value={app.identifier}
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                        onChange={selectApp}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                        <figure className="flex">
                          <img
                            alt={app?.name}
                            className="w-5 h-5 mr-1"
                            src={app?.avatar}
                          />
                          <span className="font-semibold"> {app?.name}</span>
                        </figure>
                      </label>
                      {app?.multiple_workspaces &&
                      AppSelected(app.identifier) ? (
                        <div>
                          <Select
                            id={app.identifier}
                            isMulti
                            options={app.workspaces}
                            onChange={setValues}
                          />
                          <p className="text-gray-500">
                           Select the workspaces you want to give {employeeName} access to.
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="submit"
            className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Set up applications
          </button>
        </div>
      </form>
    );
  }

  function NoInstalledApps() {
    return (
      <div className="sm:rounded-xl md:col-span-2">
        <h2 className="text-semibold py-6 text-center text-gray-900">
          You do not have any installed app on Clio!
        </h2>
        <p className="mt-1 text-base text-gray-500">
          Work apps such as Slack, Trello, Jira etc that you have connected to
          Clio will appear here.
        </p>
      </div>
    );
  }

  function AppSelected(identifier) {
    return selectedApps.find((app) => app.identifier === identifier);
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Applications
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Select the applications you want to give {employeeName} access to.
          </p>
        </div>
        {apps.length > 0 ? ShowInstalledApps() : NoInstalledApps()}
      </div>
    </>
  );
}
