import React from "react";

export default function ActionLink({ title, url, whiteBtn = false }) {
  function BlackBtn() {
    return (
      <a
        href={url}
        className="mx-2 inline-flex inline-flex items-center justify-center px-6 py-2 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
      >
        {title}
      </a>
    );
  }

  function WhiteBtn() {
    return (
      <a
        href={url}
        className="inline-flex inline-flex items-center justify-center px-6 py-2 text-sm font-semibold leading-5 text-gray-700 transition-all duration-200 bg-white border border-gray-300 border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 hover:bg-gray-200"
      >
        {title}
      </a>
    );
  }
  return <>{whiteBtn ? <WhiteBtn /> : <BlackBtn />}</>;
}
