import React from "react";

const getStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-100 text-yellow-900";
    case "Completed":
      return "bg-green-100 text-green-900";
    case "Draft":
      return "bg-blue-100 text-blue-900";
    case "Archived":
      return "bg-gray-100 text-gray-900";
    case "Scheduled":
      return "bg-purple-100 text-purple-900";
    case "Expired":
      return "bg-red-100 text-red-900";
    default:
      return "";
  }
};

const ContractStatus = ({ status }) => {
  const color = getStatusColor(status);
  return (
    <span
      className={`inline-flex items-center rounded-xl ml-2 px-2 py-0.5 text-xs font-medium text-gray-600 ${color}`}
    >
      {status}
    </span>
  );
};

export default ContractStatus;
