import React from "react";

export default function Avatar({ url , initials, size = 10, rounded='full'}) {
  const avatarSize = `h-${size} w-${size} `;
  const roundedClass = rounded === 'full' ? 'rounded-full ' : 'rounded-md ';
  function Image() {
    return <img className={avatarSize+roundedClass+'object-contain rendering-crisp-edges'} src={url} alt="" />;
  }

  function Initials() {
    return (
      <span className={avatarSize + roundedClass + 'inline-flex items-center justify-center bg-gray-900'}>
        <span className="font-medium leading-none text-white">{initials}</span>
      </span>
    );
  }
  return url === null ? <Initials /> : <Image />;
}
