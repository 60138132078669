import React, { useCallback } from "react";
import { useMergeLink } from "@mergeapi/react-merge-link";
import axios from "axios";

const MergeHrisModal = ({ mergeToken }) => {
  const onSuccess = useCallback((public_token) => {
    let page = "/people";

    axios
      .get("/applications/merge/hris", {
        params: {
          token: public_token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = page;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: mergeToken,
    onSuccess,
  });

  return (
    <a href="#" disabled={!isReady} onClick={open} className="">
      <span className="absolute inset-0" aria-hidden="true"></span>
      Sync existing employee profiles from HRIS like BambooHR, Workday, etc.
    </a>
  );
};

export default MergeHrisModal;
