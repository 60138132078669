import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from 'react-select';
import axios from "axios";

export default function SelectWorkspaces({identifier, url, formToken = null,  method = "post" }) {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);

  const cancelButtonRef = useRef(null);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    axios(teamsUrl(identifier))
    .then(res => {
      setWorkspaces(res.data);
    })
    .catch(err => {
      console.log(err);
    })
  }, []);

  function setValues(value){
    let v = value.map((v) => v.value)
    setSelectedOptions(v);
  }

  function teamsUrl(identifier){
    return `/applications/${identifier}/workspaces.json`;
  }

  function ActionButton() {
    return (
      <a
        href="#"
        className="inline-flex justify-center py-2 px-6 rounded-md border border-transparent shadow-sm text-sm font-medium text-white bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500" 
        onClick={handleOpen}
      >
        Import user accounts
      </a>
    );
  }

  return (
    <>
      <ActionButton />
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 w-[650px] pb-4 text-left shadow-xl transform transition-all sm:my-8  sm:p-6">
                  <form
                    action={url}
                    method="post"
                  >
                    <input name="authenticity_token" value={formToken} type="hidden" />
                    <input name="multiple_workspaces" value={true} type="hidden" />
                    <input name="_method" value={method} type="hidden" />
                    <input name="group" value={JSON.stringify(selectedOptions)} type="hidden" />

                    <div className="sm:flex sm:items-start">
                      
                      <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Select workspaces to import users from
                        </Dialog.Title>
                        <div className="mt-2 mb-8 block">
                        <Select isMulti options={workspaces} onChange={setValues} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Import users
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
