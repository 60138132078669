import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editor"
export default class extends Controller {
  static targets = ["trix", "templateBody", "form", "notification"];
  initialize() {
    // this.trixTarget.addEventListener("paste", this.pasteToken);
  }
  async copyToken(event) {
    try {
      const tokenNumber = event.target.dataset.tokenNumber;
      const tokenField = document.querySelector(`#token-${tokenNumber}`);
      await navigator.clipboard.writeText(
        // tokenField.value
        `<em class="text-orange-700 font-semibold">${tokenField.value}</em>`
      ); // will only work on localhost or https://
      this.notificationTarget.textContent ="Token copied!";
      this.notificationTarget.classList.add("text-emerald-600");
      this.notificationTarget.classList.add("font-semibold");
      setTimeout(this.reset(this.notificationTarget), 4000);
    } catch (err) {}
  }
  pasteToken(event) {
    const text = (event.clipboardData || window.clipboardData)?.getData("text");
    const selectedRange = this.trixTarget.editor.getSelectedRange();
    this.trixTarget.editor.setSelectedRange(selectedRange);
    this.trixTarget.editor.insertHTML(text);
    event.preventDefault();
  }

  reset(reponseText) {
    return function () {
      reponseText.textContent = "";
    };
  }
}
