import React, { Component } from "react";
import axios from "axios";

export default class ImplicitToken extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let token = location.hash.split("=")[1];
    axios.get(`${this.props.url}?code=${token}`)
      .then((res) => {
        location.href = `/applications/${this.props.identifier}`;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return null;
  }
}
