import "trix"
import "@rails/actiontext"
import "controllers"
import Trix from "trix";

Trix.config.blockAttributes.default.tagName = "p";
Trix.config.blockAttributes.default.breakOnReturn = true;
Trix.config.textAttributes.bold = { tagName: "b" };
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
