import React, { useEffect, useState } from "react";
import { CheckIcon, LinkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import Task from "./Task";
import NewTask from "./NewTask";


export default function Tasks({ assigneeId }) {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    axios
      .get(`/people/${assigneeId}/tasks.json`)
      .then((res) => {
        setTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleCheckboxChange(event) {
    if (event.target.checked) {
      completeEvent(event);
    } else {
      return false;
    }
  }

  function completeEvent(event) {
    event.preventDefault();
    let token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common["X-CSRF-Token"] = token;
    axios.defaults.headers.common["Accept"] = "application/json";
    let taskId = event.target.value;

    axios
      .put(`/people/${assigneeId}/tasks/${taskId}.json`)
      .then((res) => {
        tasks.splice(
          tasks.findIndex((task) => task.id === taskId),
          1
        );
        setTasks([...tasks]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ShowTasks({tasks}){
    return(
      <table className="min-w-full table-fixed divide-y divide-gray-300">
      <tbody className="divide-y divide-gray-200 bg-white">
      {tasks.map((task) => (
      <tr key={task.id} className="">
        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
          {task.completed ? (
            <CheckIcon className="absolute left-4 top-1/2 -mt-2 h-6 w-6 rounded border-gray-300 text-green-600 focus:ring-green-500 sm:left-6" />
          ) : (
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
              value={task.id}
              onChange={(e) => handleCheckboxChange(e)}
            />
          )}
        </td>
        <td className="whitespace-nowrap py-4 pr-3 text-sm font-mediumtext-gray-900">
          {task.name}
          { task?.document ? (
             <p className="text-gray-500 text-xs italic overflow-hidden">
              Related document: <a href={task.document_url} className="underline" target="_blank">{task.document}</a>
              </p>
          ) : null}
         
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
          assigned by {task.creator.full_name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
          {task.due_on ? `due on ${task.due_on}` : "no due date"}
        </td>
        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <Task task={task} />
        </td>
      </tr>
    ))}
      </tbody>
      </table>)
  }

  function NoTasks(){
    return (
      
        <p className="px-6 py-4 whitespace-nowrap text-sm text-center font-medium text-gray-900">
          Assigned tasks will appear here.
        </p>

    )
  }

  return (
    <div className="px-2 py-6 sm:px-3 lg:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-bold text-gray-800">Tasks</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <NewTask url={`/people/${assigneeId}/tasks`} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
             
              {tasks?.length > 0 ? <ShowTasks tasks={tasks} /> : <NoTasks />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
